import Request, { Methods } from '@/libraries/request/'
import ModelSameHero from '@/models/same-hero'
import HeroNames from '@/locales/character.yml'
import HeroSort from '@/locales/hero_sort.yml'

export default class Heroes extends Request {
  constructor (options) {
    super()
    this.locale = options.locale || null
  }

  get path () {
    return `${process.env.VUE_APP_CDN}/json/master.json`
  }

  get method () {
    return Methods.get
  }

  get parameters () {
    const params = {}
    return params
  }

  async decode ({ data }) {
    const names = HeroNames[this.locale]
    const sort = HeroSort
    const master = data.filter((d) => !!d['same_hero.id']).map(d => {
      return {
        base_id: d['same_hero.id'],
        hero_ids: d['same_hero.hero_ids'],
        series_ids: d['same_hero.series_ids']
      }
    })
    const baseIds = master.map(m => m.base_id)
    let unique = master.filter((m, i) => {
      return baseIds.indexOf(m.base_id) === i
    })
    unique = unique.map(u => {
      return Object.assign({
        sort: sort.find(s => s.hero_id === u.base_id) ? sort.find(s => s.hero_id === u.base_id)[this.locale] : names[u.base_id]
      }, u)
    })
    return unique.map(u => new ModelSameHero(u))
  }

  decodeError (error) {
    return { error: error }
  }
}
