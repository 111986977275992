<template>
  <button
    class="ButtonBack"
    @click="$emit('click')"
  >
    <img :src="require(`@images/common/${locale}/btn_back.png`)" :alt="$t('common.footer-005')">
  </button>
</template>

<script>
import LocaleMixin from '@/mixins/locale'

export default {
  mixins: [LocaleMixin]
}
</script>
<style lang="scss" scoped>
.ButtonBack {
  @at-root {
    & {
      display: block;
      @include tap-event();
      width: 108px;
      height: 112px;
    }
  }
}
</style>
