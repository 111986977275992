import ApiError from '@/libraries/errors/api'

export class Methods {
  static get get () { return 'get' }
  static get post () { return 'post' }
}

export default class Request {
  get baseUrl () {
    return ''
  }

  get path () {
    return ''
  }

  get method () {
    return Methods.get
  }

  get parameters () {
    return null
  }

  get headers () {
    return null
  }

  get auth () {
    return null
  }

  decode (json, status) {
    this.json = json
    this.status = status
    return this.json
  }

  decodeError (error) {
    return new ApiError(error)
  }
}
