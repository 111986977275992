export default class SameHero {
  constructor (json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  map (json) {
    this.baseId = json.base_id || ''
    this.heroIds = json.hero_ids.split(',').filter(Boolean)
    this.seriesIds = json.series_ids.split(',').filter(Boolean)
    // this.name = json.name || ''
    this.sort = json.sort || ''
  }

  toJSON () {
    return {
      baseId: this.id,
      heroIds: this.heroIds,
      seriesIds: this.seriesIds,
      // name: this.name,
      sort: this.sort
    }
  }
}
