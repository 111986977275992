<template>
  <div class="Character">
    <box-gold class="Character__BoxGold">
      <p v-html="$t('samecharacters.001')" />
      <p v-html="$t('samecharacters.002')" />
    </box-gold>
    <table class="Character__Table Table">
      <thead>
        <tr class="Table__Row">
          <th class="Table__Th -name">
            <span v-text="$t('result.014')" />
          </th>
          <th class="Table__Th -series">
            <span v-text="$t('result.015')" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sameHero, i) in sameHeroList" :key="i" class="Table__Row">
          <td class="Table__Td -name" v-html="heroNames(sameHero)" />
          <td class="Table__Td -series" v-html="seriesNames(sameHero)" />
        </tr>
      </tbody>
    </table>
    <button-back class="Character__ButtonBack" @click="$router.push({ name: 'Top', params: { lang : locale } })" />
    <hr class="Character__Line">
  </div>
</template>

<script>
import Api from '@/libraries/api/'
import RequestSameHeroes from '@/requests/same-heros'
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import BoxGold from '@/components/global/box-gold.vue'
import ButtonBack from '@/components/global/button-back.vue'

export default {
  name: 'Character',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    BoxGold,
    ButtonBack
  },
  data () {
    return {
      sameHeroList: []
    }
  },
  async created () {
    await this.getSameHeroes()
  },
  methods: {
    async getSameHeroes () {
      await Api.call(new RequestSameHeroes({ locale: this.locale }))
        .then((heroes) => {
          const sortedHeroes = heroes.sort((a, b) => a.baseId < b.baseId ? -1 : 1)
          this.sameHeroList = sortedHeroes
        })
        .catch(({ error }) => {
          // eslint-disable-next-lineS
          console.error(error)
        })
    },
    seriesNames (sameHero) {
      const names = sameHero.seriesIds.map(s => this.$t(`series.game${s}-title`))
      const uniqueNames = [...new Set(names)]
      return uniqueNames.join('<br>')
    },
    heroNames (sameHero) {
      const names = sameHero.heroIds.map(id => this.$t(`character.${id}`))
      const uniqueNames = [...new Set(names)]
      return uniqueNames.join('<br>')
    }
  }
}
</script>

<style lang="scss" scoped>
.Character {
  @at-root {
    & {}
    .Character__Line {
      width: 100%;
      height: 2px;
      margin: 0;
      background: url(#{$img-path}common/line_list.png) center no-repeat;
      border: 0;
    }
    // NOTE: overide generic component
    .Character__BoxGold {
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
    }
    .Character__Table {
      margin-top: 35px;
    }
    .Character__ButtonBack {
      margin: 35px auto;
    }
  }
}

.Table {
  @at-root {
    & {
      position: relative;
      width: 100%;
      background-color: rgba(0, 0, 0, .2);
      background: url(#{$img-path}common/line_list.png) no-repeat bottom;
      border-spacing: 0;
    }
    .Table__Row::after {
      content: '';
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: url(#{$img-path}common/line_list.png) no-repeat;
    }
    .Table__Th,
    .Table__Td {
      text-align: left;
    }
    .Table__Th {
      font-weight: normal;
      font-size: $fs-large;
      line-height: 1.2;
      &.-name {
        font-size: $fs-large;
        padding: 20px 0 20px 50px;
        width: 240px;
      }
      &.-series {
        font-size: $fs-large;
        padding: 20px 50px 20px 0;
      }
    }
    .Table__Td {
      vertical-align: middle;
      &.-name {
        font-size: $fs-xlarge;
        padding: 20px 0 20px 50px;
        width: 240px;
      }
      &.-series {
        color: #878787;
        font-size: $fs-large;
        padding: 20px 50px 20px 0;
      }
    }
  }
}
</style>
